import http from '../../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = '/api/checklist-acessorios-categoria?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/checklist-acessorios-categoria/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newChecklistAcessorioCategoria = (data) => {
  let url = `/api/checklist-acessorios-categoria`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateChecklistAcessorioCategoria = (id, data) => {
  let url = `/api/checklist-acessorios-categoria/${id}`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export default {
  list,
  find
}
