<template>
  <window-content v-if="!loading && !errors && !success">
    <div class="wrapper-sm">
      <e-row class="m-b-sm">
        <e-col style="max-width: 150px">
          <erp-s-field
              view="lr"
              label="ID:"
              label-width="90px"
          >
            <erp-input simple-border v-model="acessorio.id" readonly />
          </erp-s-field>
        </e-col>

      </e-row>
      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Nome:"
              label-width="90px"
          >
            <erp-input autofocus simple-border v-model="acessorio.nome" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              view="lr"
              label="Tipo:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-select
                placeholder="Qualquer"
                :options="arrayTiposAcessorio"
                v-model="acessorio.tipo"
            />
          </erp-s-field>
          <!--<u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaTipo" />-->
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              label="Tipos de Bem:"
              view="lr"
              label-width="90px"
          >
            <erp-select multiple v-model="acessorio.tiposBem" placeholder="Nenhum" :options="tiposFiltered" :loading="loadingTipos" size="2">
              <template v-slot:itemLabel="props">
                <span class="erp-select-level" :class="{'font-bold': Number(props.opt.level) === 0}"
                      :style="'padding: 0 0 0 ' + (10 * Number(props.opt.level)) + 'px' ">{{props.opt.label}}</span>
              </template>
            </erp-select>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col style="max-width: 250px; display: flex;">
          <erp-s-field
              view="lr"
              label="Categoria:"
              label-width="90px"
              style="display: flex;"
          >
            <erp-select
                placeholder="Qualquer"
                :options="categoria.serverData"
                v-model="acessorio.categoria"
                :loading="categoria.loading"
            />
          </erp-s-field>
          <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="adicionaCategoria" />
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Obrigatório"
              label-width="90px"
          >
            <erp-checkbox v-model="acessorio.obrigatorio" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row>
        <e-col>
          <erp-s-field
              view="lr"
              label="Ativo"
              label-width="90px"
          >
            <erp-checkbox v-model="acessorio.active" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm">
        <e-col>
          <erp-s-field
              view="lr"
              label="Ordem:"
              label-width="90px"
          >
            <erp-input simple-border v-model="acessorio.order" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-b-sm">
        <e-col>
          <erp-s-field
              view="lr"
              label="Valor padrão:"
              label-width="90px"
          >
            <erp-input simple-border v-model="acessorio.valorPadrao" />
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="row-valores" v-if="habilitarValores && acessorio.valores.length">
        <e-col style="max-width: 250px">
          <erp-s-field
              view="lr"
              :label="`Valor ${index + 1}:`"
              label-width="90px"
              v-for="(valor, index) in acessorio.valores" :key="index"
          >
            <div style="display: flex">
              <erp-input simple-border :value="valor" readonly />
              <u-icon name="times" type="fa" icon-style="light"
                      class="cursor-pointer m-l-sm text-negative"
                      @click.native="removeValor(index)"
                      v-if="valor"
              />
            </div>
          </erp-s-field>
        </e-col>
      </e-row>

      <e-row class="m-t-sm" v-if="habilitarValores">
        <e-col style="display: flex; max-width: 250px">
          <erp-s-field
              view="lr"
              label="Adicionar valor:"
              label-width="90px"
          >
            <erp-input simple-border v-model="tmpValor" ref="adicionaValor" @keyup.native.enter="addValor" />
          </erp-s-field>
          <u-icon name="plus-circle" type="fa" icon-style="light" class="m-l-xs cursor-pointer" @click.native="addValor" />
        </e-col>
      </e-row>
    </div>

    <window-footer class="text-right">
      <div class="inline-block window-actions">
        <u-btn label="Cancelar" icon="chevron-left" icon-type="fa" icon-style="light" flat no-caps
               @click="$uloc.window.close($root.wid)" />
        <u-btn label="Salvar (F2)" icon="save" icon-type="fa" icon-style="light" flat no-caps
               @click="save"
               v-shortkey="['f2']" @shortkey.native="save" />
      </div>
    </window-footer>
  </window-content>
  <window-success v-else-if="success">
    <slot name="success">
      <div class="text-center">
        <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
      </div>
      <h5 class="m-t" v-if="id">{{ id ? 'Atualizado com sucesso!' : 'Acessório registrado com sucesso!' }}</h5>
      <div class="text-center m-t">
        <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
      </div>
    </slot>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import {
  ErpSField,
  ErpInput,
  ErpSelect,
  ErpCheckbox
} from 'uloc-vue-plugin-erp'
import {
  // ErpTabs,
  // ErpTabItem,
  WindowContent,
  // WindowBody,
  WindowFooter
} from 'uloc-vue-plugin-erp'
import ERow from "../../../../layout/components/Row"
import ECol from "../../../../layout/components/Col"
import {
  getTipoString,
  tiposChecklist,
  // isBoolean
} from "@/domain/remocao/helpers/gerenciamento-checklist"
import {list as listaCategorias} from "../../../../../domain/remocao/services/gerenciamento-checklist/checklist-acessorios-categoria"
import {
  newChecklistAcessorio,
  updateChecklistAcessorio,
  find
} from "@/domain/remocao/services/gerenciamento-checklist/checklist-acessorios"
import WindowSuccess from "../../../../layout/window/Success"
import TimerCloseWindow from "../../../../layout/window/TimerClose"
import WindowLoading from "../../../../layout/window/Loading"
import windowAcessorioCategoria from '../../../windows/gerenciamento-checklist/checklistAcessorioCategoriaWindow'
import {listTiposBem} from "@/domain/cadastro/services"
import {treeCheck} from "@/components/cadastro/components/tiposbem/helpers/treeCheck"

export default {
  name: 'ChecklistAcessorioWindow',
  props: ['id'],
  data () {
    return {
      loading: !!this.id,
      errors: null,
      success: false,
      status: null,
      acessorio: {
        nome: null,
        obrigatorio: false,
        categoria: null,
        valorPadrao: null,
        valores: [],
        tipo: null,
        tiposBem: [],
        order: 0,
        active: true,
      },
      tipo: {
        loading: true,
        serverData: []
      },
      categoria: {
        loading: true,
        serverData: []
      },
      tmpValor: null,
      showDashOptions: false,
      loadingTipos: false,
      bemTipos: []
    }
  },
  computed: {
    arrayTiposAcessorio () {
      const options = []
      for (const tipo in tiposChecklist) {
        options.push({label: getTipoString(tipo), value: +tipo})
      }
      return options
    },
    habilitarValores () {
      const tipo = tiposChecklist[this.acessorio.tipo] || {}
      return tipo.habilitarValores/* && !this.isTipoBoolean*/
    },
    /*isTipoBoolean () {
      return isBoolean(this.acessorio)
    },*/
    tiposFiltered () {
      if (!this.bemTipos) {
        return []
      }
      return this.treeCheck(this.bemTipos)
    }
  },
  created () {
  },
  mounted () {
    this.loadCategorias()
    listTiposBem(200, 1, null, true)
        .then(({data}) => {
          this.bemTipos = data
          console.log('Teste ', data)
          this.loadingTipos = false
          this.id && this.load()
        })
        .catch(error => {
          console.error(error)
        })
  },
  methods: {
    changeTab ({tab}) {
      this.tabActive = tab
    },
    load () {
      this.loading = true
      find(this.id)
          .then(response => {
            const data = response.data
            if (data.categoria?.id) {
              data.categoria = data.categoria.id
            }
            if (data.tiposBem && Array.isArray(data.tiposBem)) {
              data.tiposBem = data.tiposBem.map(tipo => {
                return tipo.id
              })
            }
            this.acessorio = data
          })
          .catch(error => {
            console.log(error)
            this.errors = error
          })
          .finally(() => {
            this.loading = false
          })
    },
    loadCategorias () {
      this.categoria.loading = true
      listaCategorias(9999999, 1, '')
          .then(response => {
            this.categoria.serverData = response.data.result.map(c => ({label: c.nome, value: c.id}))
          })
          .catch(error => {
            console.log(error)
            this.$uloc.notify({
              type: 'negative',
              message: 'Houve um erro ao carregar as categorias. Consulte o suporte'
            })
          })
          .finally(() => {
            this.categoria.loading = false
          })
    },
    save () {
      this.loading = true
      const data = JSON.parse(JSON.stringify(this.acessorio))
      if (data.categoria?.id) {
        data.categoria = data.categoria.id
      }
      if (this.isTipoBoolean) {
        data.valores = {type: 'true/false'}
      }
      data.tiposBem = data.tiposBem.filter(tipo => typeof tipo === 'number')

      const method = this.id ? updateChecklistAcessorio(this.id, data) : newChecklistAcessorio(data)
      method
          .then(() => {
            const cbSuccess = () => {
              this.success = true
              this.$nextTick(() => {
                this.loading = false
                this.success = true
                this.$uloc.window.emit(this.$root.wid, 'update', this.id)
                this.$refs.timer.start()
                // this.$uloc.window.close(this.$root.wid)
              })
            }
            cbSuccess()
          })
          .catch(error => {
            this.errors = error.data
            console.error(error, error.data)
            this.loading = false
          })
    },
    adicionaCategoria () {
      this.windowAcessorioCategoria()
    },
    addValor () {
      const valor = (this.tmpValor || '').trim()
      if (!valor) return
      const valorJaExiste = this.acessorio.valores.indexOf(valor) !== -1
      if (valorJaExiste) return this.$uloc.notify({type: 'warning', message: 'Valor já adicionado'})
      this.acessorio.valores.push(valor)
      this.tmpValor = ''
      setTimeout(() => {
        this.$refs.adicionaValor.focus()
      }, 100)
    },
    removeValor (index) {
      this.acessorio.valores.splice(index, 1)
    },
    windowAcessorioCategoria,
    treeCheck
  },
  components: {
    WindowLoading,
    TimerCloseWindow,
    WindowSuccess,
    ECol,
    ERow,
    ErpSField,
    ErpInput,
    ErpSelect,
    // ErpTabs,
    // ErpTabItem,
    WindowContent,
    // WindowBody,
    WindowFooter,
    ErpCheckbox
  }
}
</script>

<style scoped>
.row-valores {
  padding: 10px !important;
  max-height: 100px;
  overflow-y: auto;
}
</style>
