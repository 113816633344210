export default function (id) {
    let window = 'checklist.acessorio.categoria'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: id ? `Categoria #${id}` : 'Nova categoria de acessório',
        width: '400',
        height: '300',
        minHeight: '300px',
        maxWidth: '900',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        contentClass: 'overflow-hidden',
        props: {
            id
        }
    }, () => import('../../components/window/gerenciamento-checklist/ChecklistAcessorioCategoria.vue'))
        .then((wid) => {
            console.log(wid)
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    console.log('Atualizado: ', val)
                    this.loadCategorias?.()
                }
            })
        }) // return wid
}
